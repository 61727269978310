import React, {useEffect} from 'react'
import './footer.css'
import './footer.scss'
import { FaComputer } from "react-icons/fa6";
import background from "../../Assets/background.png";
//import video2 from '../../Assets/video2.mp4'
import { FiSend } from "react-icons/fi";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { FaTripadvisor } from "react-icons/fa";
import { FiChevronRight } from "react-icons/fi";

import aos from 'aos'
import 'aos/dist/aos.css'

const Footer = () => {
    // lets create a react hook to add a scroll animation......
  useEffect(() => {
   aos.init({duration: 2000})
  }, [])

  return (
    <section className='footer'>
      <div className="videoDiv">
        <img src={background} alt="Loading"/>
        {/*<video src={video2} loop autoPlay muted type="video/mp4"></video>*/}
      </div>

      <div className="secContent container">
         <div className="contactDiv flex">
           <div data-aos="fade-up" className="text">
            <small>KEEP IN TOUCH WITH</small>
             <h2>Your Digital World</h2>
           </div>

           <div className="inputDiv flex">
             <input data-aos="fade-up" type="text" placeholder='Enter Email Adress' />
              <button data-aos="fade-up" className='btn flex' type='submit'>
                SEND <FiSend className="icon"/>
              </button>
           </div>
         </div>

        <div className="footerCard flex">
          <div className="footerIntro flex">
             <div className="logoDiv">
              <a href="/" className='logo flex'>
              <FaComputer className="icon" /> AkdmeTech.
              </a>
             </div>

             <div data-aos="fade-up" className="footerParagraph">
                Provides technical support to our users by researching and answering questions, troubleshooting problems, and maintaining workstation and LAN performance. Provides answers to clients by identifying problems, researching answers, and guiding clients through corrective steps. Our group of experts track the status of problems, use other special software to analyze problems and monitor issues such as the status of a company telecommunications network
             </div>

             <div data-aos="fade-up" className="footerSocials flex">
             <AiOutlineTwitter className="icon"/>
             <AiFillYoutube className="icon"/>
             <AiFillInstagram  className="icon"/>
             <FaTripadvisor  className="icon"/>
             </div>
          </div>

          <div className="footerLinks grid" >

             {/*Group One*/}
            <div data-aos="fade-up" data-aos-duration="3000" className="linkGroup">
              <span className="groupTitle">
                OUR AGENCY
              </span>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                Services
              </li>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                Insurance
              </li>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                Agency
              </li>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                Tourism
              </li>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                Payment
              </li>
            </div>
             
             {/*Group Two*/}
            <div data-aos="fade-up" data-aos-duration="4000" className="linkGroup">
              <span className="groupTitle">
                LAST MINUTE
              </span>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                London
              </li>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                California
              </li>
                
              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                 Indonesia
              </li>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                Europe
              </li>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                Oceania
              </li>
            </div>

             {/*Group Three*/}
            <div data-aos="fade-up" data-aos-duration="5000" className="linkGroup">
              <span className="groupTitle">
                PARTNERS
              </span>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                Bookings
              </li>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                Rentcars
              </li>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                Hostelworld
              </li>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                Trivago
              </li>

              <li className="footerList flex">
              <FiChevronRight className="icon"/>
                TripAdvisor
              </li>
            </div>

          </div>

          <div className="footerDiv flex">
             <small>AKDMETECH BEST DIGITAL WORLD </small>
             <small>COPYRIGHTS RESERVED - AKDMETECH 2024</small>
          </div>
          
        </div>
      </div>
    </section>
  )
}

export default Footer
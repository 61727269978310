import React from 'react'


import Main from '../Home/Home';
import Home from '../Main/Main';

const Global = () => {
  return (
    <>
    <Main /> 
     <Home />
      
    </>
  )
}

export default Global
import React from 'react'
import "./Study.css";

import Hostudy from './Hostudy/Hostudy'
import Homain from './Homain/Homain'


const Study = () => {
  return (
    <>
      <Hostudy />
      <Homain />
    </>
  )
}

export default Study
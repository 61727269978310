import React, {useEffect} from "react";
import "./home.css";
import "./home.scss";
//import background from "../../Assets/background.png";
//import emailjs from '@emailjs/browser';
//import './EmailForm.css';
import video from "../../Assets/video2.mp4";
//import { FaLocationDot } from "react-icons/fa6";
//import { HiFilter } from "react-icons/hi";
import { FiFacebook } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { SiTripadvisor } from "react-icons/si";
import { BsListTask } from "react-icons/bs";
import { TbApps } from "react-icons/tb";

import aos from 'aos'
import 'aos/dist/aos.css'

const Home = () => {
 // lets create a react hook to add a scroll animation......
 useEffect(() => {
   aos.init({duration: 2000})
 }, [])
  return (
    <section className="home">
      <div className="overlay"></div>
      {/*<img src={background} alt="Loading"/>*/}
     <video src={video} muted autoPlay loop type="video/mp4"></video>

      <div className="homecontent container">
        <div className="textDiv">
          <span data-aos="fade-up" className="smallText">Our Helpdesk</span>

          <h1 data-aos="fade-up" className="homeTitle">Create your Ticket</h1>
        </div>

        <form className="inputForm" action="">
            <div data-aos="fade-up" className="cardDiv grid">
              <div className="nameInput">
                <label htmlFor="Name">Your Name:</label>
                <div className="input flex">
                  <input type="text" name="from_name" placeholder="Enter Name here...." />
                </div>
              </div>
              
              <div className="phoneInput">
                <label htmlFor="Phone">Your Phone:</label>
                <div className="input flex">
                  <input type="text" name="" placeholder="Enter your Phone here...." />
                </div>
              </div>

              <div className="emailInput">
                <label htmlFor="Email">Your Email Address:</label>
                <div className="input flex">
                  <input type="email" placeholder="Enter Email here...." />
                </div>
              </div>

              <div className="issueInput">
                <div className="label_total flex">
                  <label>Your Issue Description:</label>
                </div>
                <div className="input flex">
                  <input type="textarea" placeholder="Enter Issue Description here...."/>
                </div>
              </div>

              <div className="searchOptions flex">
                <span>SUBMIT</span>
              </div>
            </div>
        </form>
        <div data-aos="fade-up" className="homeFooterIcons flex">
          <div className="rightIcons">
            <FiFacebook className="icon" />
            <FaInstagram className="icon" />
            <SiTripadvisor className="icon" />
          </div>

          <div className="leftIcons">
            <BsListTask className="icon" />
            <TbApps className="icon" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;

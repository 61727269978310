import React, {useState} from "react";
import "./navbar.scss";
import { FaComputer } from "react-icons/fa6";
import { AiFillCloseCircle } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";

const Navbar = () => {
   const [active, setActive] = useState('navBar')
 
  const showNav = () => {
    setActive('navBar activeNavbar')
  }

  const removeNavbar = () => {
    setActive('navBar')
  }
   return (
    <section className='navBarSection'>
      <header className="header flex">

        <div className="logoDiv">
          <a href="/" className="logo flex">
            <h1>
            <FaComputer className="icon" /> AkdmeTech.
            </h1>
          </a>
        </div>

        <div className={active}>
          <ul className="navlists flex">
            <li className="navItem">
              <a href="/" className="navLink">
              Home
              </a>
            </li>

            <li className="navItem">
              <a href="/Study" className="navLink">
                Services
              </a>
            </li>
            
            <li className="navItem">
              <a href="/" className="navLink">
                About
              </a>
            </li>

            <li className="navItem">
              <a href="/" className="navLink">
                Pages
              </a>
            </li>

            <li className="navItem">
              <a href="/" className="navLink">
                News
              </a>
            </li>

            <li className="navItem">
              <a href="/" className="navLink">
                Contact
              </a>
            </li>
            
          </ul>
          <div onClick={removeNavbar} className="closeNavbar">
            <AiFillCloseCircle className="icon"/>
          </div>
        </div>
         <div onClick={showNav} className="toggleNavbar">
         <TbGridDots className="icon" />
         </div>
      </header>
    </section>
  );
};

export default Navbar;

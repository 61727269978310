import React, {useEffect} from 'react'
import '../Homain/Homain.scss'
import '../Homain/Homain.css'
//import {Link} from 'react-router-dom';
//import { Link } from 'react-router-dom';
//import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineClipboardCheck } from "react-icons/hi";

import * as FaIcons from "react-icons/fa";
import * as MaIcons from "react-icons/md";
import * as SaIcons from "react-icons/pi";
import * as IoIcons from "react-icons/io5";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gr";
import * as DiIcons from "react-icons/ri";
import * as ViIcons from "react-icons/gr";
import* as TiIcons from "react-icons/ti";


import img from '../../../Assets/imag11.jpg'
import img2 from '../../../Assets/imag13.jpg'
import img3 from '../../../Assets/imag12.jpg'
import img4 from '../../../Assets/imag15.jpg'
import img5 from '../../../Assets/imag18.jpg'
import img6 from '../../../Assets/imag6.jpg'
import img7 from '../../../Assets/imag7.jpg'
import img8 from '../../../Assets/imag8.jpg'
import img9 from '../../../Assets/imag9.jpg'

import aos from 'aos'
import 'aos/dist/aos.css'

const Data = [
    {
      id:1,
      imgSrc: img,
      destFile: 'Office 365 Courses',
      icon: <TiIcons.TiVendorMicrosoft/>,
      href: 'http://helpdesk.akdmetech.com',
      location: 'Click the Support Image',
      grade: 'Create Your Ticket Here',
      
      description: 'Provides technical support to our users by researching and answering questions, troubleshooting problems, and maintaining workstation and LAN performance. Provides answers to clients by identifying problems, researching answers, and guiding clients through corrective steps. Our group of experts track the status of problems, use other special software to analyze problems and monitor issues such as the status of a company telecommunications network'
    },
    {
      id:2,
      imgSrc: img2,
      destFile: 'Microsoft Intune',
      icon: <SaIcons.PiStudentDuotone/>,
      href: "/Study",
      location: 'Digital Courses',
      grade: 'Your Learning Center',
      description: 'Provides technical support to our users by researching and answering questions, troubleshooting problems, and maintaining workstation and LAN performance. Provides answers to clients by identifying problems, researching answers, and guiding clients through corrective steps. Our group of experts track the status of problems, use other special software to analyze problems and monitor issues such as the status of a company telecommunications network.'
    },
    {
      id:3,
      imgSrc: img3,
      destFile: 'Microsoft Azure Cloud',
      icon: <FaIcons.FaNetworkWired/>,
      location: 'Networking World',
      grade: 'Disgn Setup and Config',
      
      description: 'Provides technical support to our users by researching and answering questions, troubleshooting problems, and maintaining workstation and LAN performance. Provides answers to clients by identifying problems, researching answers, and guiding clients through corrective steps. Our group of experts track the status of problems, use other special software to analyze problems and monitor issues such as the status of a company telecommunications network.'
    },
    {
      id:4,
      imgSrc: img4,
      destFile: 'Computer and Network Security',
      icon: <MaIcons.MdOutlineSecurity/>,
      location: 'We Secure Your Infra ',
      grade: 'Your Security Center',
      description: 'Provides technical support to our users by researching and answering questions, troubleshooting problems, and maintaining workstation and LAN performance. Provides answers to clients by identifying problems, researching answers, and guiding clients through corrective steps. Our group of experts track the status of problems, use other special software to analyze problems and monitor issues such as the status of a company telecommunications network.'
    },
    {
      id:5,
      imgSrc: img5,
      destFile: 'Akdme Hardeware',
      icon:<IoIcons.IoHardwareChipSharp />,
      location: 'Your Hardware Center',
      grade: 'Computer Hardware Repair',
      description: 'Provides technical support to our users by researching and answering questions, troubleshooting problems, and maintaining workstation and LAN performance. Provides answers to clients by identifying problems, researching answers, and guiding clients through corrective steps. Our group of experts track the status of problems, use other special software to analyze problems and monitor issues such as the status of a company telecommunications network.'
    },
    {
      id:6,
      imgSrc: img6,
      destFile: 'Akdme Cloud Services',
      icon:<AiIcons.AiOutlineCloudServer />,
      location: 'Your Cloud Center',
      grade: 'Cloud Services & Solutions',
      description: 'Provides technical support to our users by researching and answering questions, troubleshooting problems, and maintaining workstation and LAN performance. Provides answers to clients by identifying problems, researching answers, and guiding clients through corrective steps. Our group of experts track the status of problems, use other special software to analyze problems and monitor issues such as the status of a company telecommunications network.'
    },
    {
      id:7,
      imgSrc: img7,
      destFile: 'Akdme System Infra ',
      icon:<GiIcons.GrSystem/>,
      location: 'Your System Center ',
      grade: 'Windows and Linux Infra',
      description: 'Provides technical support to our users by researching and answering questions, troubleshooting problems, and maintaining workstation and LAN performance. Provides answers to clients by identifying problems, researching answers, and guiding clients through corrective steps. Our group of experts track the status of problems, use other special software to analyze problems and monitor issues such as the status of a company telecommunications network.'
    },
    {
      id:8,
      imgSrc: img8,
      destFile: 'Akdme OpenSource Solutions',
      icon:<DiIcons.RiOpenSourceLine />,
      location: 'Your Open Source Center',
      grade: 'OpenSource Infra Solutions',
      description: 'Provides technical support to our users by researching and answering questions, troubleshooting problems, and maintaining workstation and LAN performance. Provides answers to clients by identifying problems, researching answers, and guiding clients through corrective steps. Our group of experts track the status of problems, use other special software to analyze problems and monitor issues such as the status of a company telecommunications network'
    },
    {
      id:9,
      imgSrc: img9,
      destFile: 'Akdme Virtualisation',
      icon:<ViIcons.GrVmware/>,
      location: 'Your Virtualisation Center',
      grade: 'Virtualisation Infra Solutions',
      description: 'Provides technical support to our users by researching and answering questions, troubleshooting problems, and maintaining workstation and LAN performance. Provides answers to clients by identifying problems, researching answers, and guiding clients through corrective steps. Our group of experts track the status of problems, use other special software to analyze problems and monitor issues such as the status of a company telecommunications network.'
    },
    
    ]



const Homain = () => {
   // lets create a react hook to add a scroll animation......
    useEffect(() => {
    aos.init({duration: 2000})
  }, [])

  return (
    <section className='main container section'>
      <div className="secTitle">
        <h3 data-aos="fade-right" className="title">
          Our Akdme Services
        </h3>
      </div>
      <div className="secContent grid">
       {
       Data.map(({id, imgSrc, destFile, icon, href, location, grade, fees, description })=>{
            return(
              <div key={id} data-aos="fade-up" className="singleDestination">
                 <div className="imageDiv">
                 <a href={href}>
                  <img src={imgSrc} alt={destFile} 
                  />
                  </a>
                 </div>
                 
                 <div className="cardInfo">
                   <h4 className="destTitle">{destFile}</h4>

                   <span className="continent flex">
                   <a href={href}>
                    <span className='icon'>{icon}</span></a>
                    <span className="name">{location}</span>
                   </span>

                 <div className="fees flex">
                  <div className="grade">
                    <span>{grade}</span>
                  </div>
                  <div className="price">
                    <h5>{fees}</h5>
                  </div>
                 </div>

                 <div className="desc">
                  <p>{description}</p>
                 </div>

                 <button className=' btn flex'>
                  DETAILS <HiOutlineClipboardCheck className='icon'/>
                 </button>
                 </div>  
              </div>
            )
        })
       }
       
      </div>
    </section>
  )
}

export default Homain
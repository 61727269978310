import { BrowserRouter, Routes, Route, Link, NavLink } from "react-router-dom";
import React from "react";
import "./App.css";
import Navbar from "./Components/Navbar/navbar";
import Footer from "./Components/Footer/Footer";
import Study from './Components/Study/Study';
import Global from './Components/Global/Global'



const App = () => {
  return (
    <BrowserRouter>
      <>
      <Navbar />     
      </>
        <Routes>
          <Route exact path="/" element={<Global />} />
          <Route path="/Study" element={<Study />} />
        </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
